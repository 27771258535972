<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h4>
          Exported Report
        </h4>
      </b-card-header>
      <b-table-simple
        hover
        striped
        bordered
        responsive
        class="rounded-bottom mb-0"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>File Name</b-th>
            <b-th>Requested Date/Time</b-th>
            <b-th>Download</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-show="showLoading"
            class="b-table-busy-slot"
          >
            <b-td colspan="11">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </b-td>
          </b-tr>
          <template v-if="data.length>0 && !showLoading">
            <b-tr
              v-for="(tr, indextr) in data"
              :key="indextr"
            >
              <b-td>
                {{ tr.file_name }}
              </b-td>
              <b-td>{{ tr.request_time }}</b-td>
              <b-td>
                <a
                  :href="'https://anypaystorage.oss-ap-southeast-3.aliyuncs.com/'+tr.Downloaded_link"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  />
                </a>
              </b-td>
            </b-tr>
          </template>
          <template v-else-if="!showLoading">
            <b-tr rowspan="10">
              <b-td
                colspan="11"
                class="text-center"
              >
                No data available
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>
<script>
import {
  BTableSimple,
  BCard,
  BCardHeader,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BSpinner,
} from 'bootstrap-vue'
import axios from 'axios'
import UserReportService from '../../services/user.report.service'

export default {
  components: {
    BTableSimple,
    BCard,
    BCardHeader,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BSpinner,
  },
  data() {
    return {
      data: [],
      showLoading: false,
    }
  },
  mounted() {
    this.getExportHistory()
  },
  methods: {
    getExportHistory() {
      this.showLoading = true
      UserReportService.exportHistory().then(response => {
        console.log(response)
        this.data = response
        this.showLoading = false
      })
    },
    async downloadItem({ url, label }) {
      // console.log(url)
      const response = await axios.get(
        `https://anypaystorage.oss-ap-southeast-3.aliyuncs.com/${url}`,
        { responseType: 'blob' },
      )
      const blob = new Blob([response.data], { type: 'application/csv' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = label
      link.click()
      URL.revokeObjectURL(link.url)
    },
  },
}
</script>
    <style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card {
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
